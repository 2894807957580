import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, PrimaryBtn } from "components/library"
import { ArrowRight } from "react-feather"
import { NotionArticle, useArticle } from "utils"
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from "gatsby-plugin-react-i18next"
import { Language } from "types/translation"
import { DownloadDocument } from "components/common/download-document/component"
import { CtaBlock } from "components/common/cta-block"
import { getDataManagementLink, getIndustryNewsLink, getTLD } from "utils/general/links"
import { NotionRenderer } from "react-notion-x"

const caseStudyFile =
  "https://s3.ap-southeast-1.amazonaws.com/engineering-polymerize/Case%20Study_Elastomeric_Sealing_Materials___.pdf"
const caseStudyFileJp =
  "https://s3.ap-southeast-1.amazonaws.com/engineering-polymerize/Case%20Study_Elastomeric_Sealing_Materials___.pdf"
const caseStudyFileZh = "https://engineering-polymerize.s3.ap-southeast-1.amazonaws.com/%E6%A1%88%E4%BE%8B%E5%88%86%E6%9E%90-%E5%BC%B9%E6%80%A7%E5%AF%86%E5%B0%81%E4%BB%B6(Case-Study-Elastomeric-Sealing-Materials).pdf"

const CaseStudies: {
  [key in Language]: string
} = {
  "en": caseStudyFile,
  "zh": caseStudyFileZh,
  "ja": caseStudyFileJp,
  "fr": caseStudyFile,
  "kr": caseStudyFile
}

type P = {
  pageContext: {
    article: NotionArticle
  }
}

export default function CaseStudy({ pageContext: { article } }: P) {
  const { t } = useTranslation()
  const { language } = useI18next()
  const [open, setOpen] = useState(false)
  const { author, title, featuredImage } = useArticle({ article })
  
  return (
    <>
      <DownloadDocument
        open={open}
        setOpen={setOpen}
        file={CaseStudies[language as Language]}
        document={title}
        documentName="Case Study"
        showMoreDetails
      />

      <main className="flex flex-col">
        <section className="bg-hero-bg-flat-mobile sm:bg-hero-bg-flat bg-no-repeat bg-cover pb-12 sm:pb-20 pt-32 sm:pt-[180px] -mt-[115px]">
          <Container className="flex flex-col gap-4 sm:gap-12 text-white">
            <span className="text-lg sm:text-2xl">
              {t("common.words.case-study")}
            </span>

            <span className="text-2xl font-bold sm:text-5xl lg:text-6xl">
              {title}
            </span>

            <PrimaryBtn
              className="bg-theme-orange hover:bg-theme-orange/90 md:text-lg max-w-max py-4 px-6"
              onClick={() => setOpen(true)}
            >
              {t("case-study-page.hero.cta")}
            </PrimaryBtn>
          </Container>
        </section>

        {/* CONTENT */}
        <Container className="flex gap-10 py-20 flex-col sm:flex-row items-start justify-center">
          <div className="flex flex-col gap-10 sm:border-r sm:pr-10">
            <article className="prose w-full sm:max-w-3xl prose-li:marker:text-inherit">
              <img src={featuredImage} alt={title} className="w-full h-auto" />
              <NotionRenderer recordMap={article} />
            </article>


            <button
              className="text-lg cursor-pointer text-left"
              onClick={() => setOpen(true)}
            >
              <Trans
                i18nKey="case-study-page.article.cta"
                components={{
                  spantag: <span />,
                }}
              />
            </button>
          </div>

          {/* AUTHOR */}
          <div className="w-80 shrink-0 sticky flex flex-col gap-12 top-8 flex-initial">
            <div className="flex gap-4 items-center">
              <img
                src={author.image}
                alt={author?.name}
                className="w-16 h-16 shrink-0 rounded-full overflow-hidden"
              />

              <div className="flex flex-col">
                <p className="font-semibold">
                  {author?.name}
                </p>
                <p>
                  {author?.bio}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-xl uppercase text-neutral-600">
                {t("common.words.contact")}
              </h3>
              <div className="w-full h-[1px] bg-black/30"></div>
              <ul className="flex flex-col px-4 py-2 gap-2">
                <li>
                  <Link
                    to="/contact-us/"
                    className="flex items-center gap-2 uppercase text-theme-orange"
                  >
                    <span>{t("common.words.contact-us")}</span>
                    <ArrowRight size={20} />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-xl uppercase text-neutral-600">
                {t("common.words.blog")}
              </h3>
              <div className="w-full h-[1px] bg-black/30"></div>
              <ul className="flex list-disc flex-col px-8 py-2 gap-2">
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://blog.polymerize.${getTLD(process.env.GATSBY_LANGUAGE_SELECTED)}/category/polymerize`
                    }
                  >
                    Polymerize
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://blog.polymerize.${getTLD(process.env.GATSBY_LANGUAGE_SELECTED)}/category/ai/ml`
                    }
                  >
                    AI/ML
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={getDataManagementLink(process.env.GATSBY_LANGUAGE_SELECTED)
                    }
                  >
                    {t("common.words.data-management")}
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      getIndustryNewsLink(process.env.GATSBY_LANGUAGE_SELECTED)
                    }
                  >
                    {t("common.words.industry-news")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>

        <CtaBlock className="hidden sm:inline-flex" />
      </main>
    </>
  );
}

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
